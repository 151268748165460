import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/builder"
import { CoreFields } from "./fragment" // eslint-disable-line
import { CoreBlockFragment } from "../components/blocks/CoreBlock" // eslint-disable-line

/**
 * This page should query the posts based on the context provided and pass the results to the post-list block
 * No post-list block? Show an error
 */
class ListRooms extends Component {
  render() {
    // kill if data.wp.themeOptions.accommodationActive is false/null
    if (!this.props.data.wp.themeOptions.accommodationActive) return

    const blocks = buildBlocks(
      this.props.data.wp.page.blocks,
      {
        rooms: this.props.data.wp.rooms.edges,
        ...this.props.pageContext,
      },
      {
        rooms: this.props.data.wp.rooms.edges,
        ...this.props.pageContext,
        bookingLink: this.props.data.wp.themeOptions.roomBookingLink,
      }
    )

    return (
      <Layout
        meta={this.props.data.wp.page.Meta}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wp.page.PageCTA}
        title={this.props.data.wp.page.title}
        themeOptions={this.props.data.wp.themeOptions}
        ii={this.props.data.wp.themeOptions.ThemeOptions.individualInnsBranding}
        pageContext={this.props.pageContext}
      >
        {blocks}
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: ID!, $ids: [ID!]) {
    wp {
      page(id: $id, idType: DATABASE_ID) {
        id
        slug
        title
        Meta {
          fieldGroupName
          metaDescription
          metaTitle
          ogDescription
          ogTitle
          twitterCardType
          twitterDescription
          twitterTitle
          twitterImage {
            uri
            sourceUrl
          }
          ogImage {
            uri
            sourceUrl
          }
        }
        PageCTA {
          actionType
          ctaText
          fieldGroupName
          link {
            url
          }
        }
        ... on wp_BlockEditorContentNode {
          blocks {
            ...CoreBlock
            ...CoreFields
          }
        }
      }
      rooms(first: 20, where: { in: $ids }) {
        edges {
          node {
            id
            title
            uri
            amenities {
              __typename
              ... on wp_Amenity {
                iconUri
                name
              }
            }
            roomType
            RoomData {
              excerpt
              listImage {
                sourceUrl
                mediaItemUrl
                mediaItemUrlSharp {
                  id
                  publicURL
                  childImageSharp {
                    fluid(quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              price
            }
          }
        }
      }
      themeOptions {
        accommodationActive
        roomBookingCTAText
        roomBookingImage
        roomBookingImageText
        roomBookingLink
        tableBookingAccountId
        tableBookingActive
        tableBookingBrandId
        tableBookingCTAText
        tableBookingImage
        tableBookingImageText
        tableBookingRestaurantId
        liveResSiteId
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
          individualInnsBranding
        }
      }
    }
  }
`

export default ListRooms
